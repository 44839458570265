import "./skip-link-focus-fix";
import "script-loader!slick-carousel";
import "script-loader!lity";

(function ($) {
  $(document).ready(function () {
    fullWidthVideo();
    courseFacilitatorsPopups();
    testimonialsPopups();
    accordionsBlock();
    menuToggle();
    searchToggle();
    accordionShortcode();
    featuredTestimonials();
    videoLibrary();
    featuredCourses();
    productTabs();
    homepageNews();
  });

  function homepageNews() {
    const accTrigger = document.querySelector('#accTrigger');

    if (accTrigger) {
      let openState = false;
      document.querySelector("#accTrigger").addEventListener("click", (e) => {
        document.querySelector("#accTrigger").classList.toggle("active");
        if (openState) {
          document
            .querySelector(
              ".exciting-news__accCon__dropCon-content p:first-child"
            )
            .classList.toggle("active");
          setTimeout(() => {
            document.querySelector("#accDropDown").classList.toggle("active");
          }, 750);
          openState = false;
        } else {
          document.querySelector("#accDropDown").classList.toggle("active");
          setTimeout(() => {
            document
              .querySelector(
                ".exciting-news__accCon__dropCon-content p:first-child"
              )
              .classList.toggle("active");
          }, 750);
          openState = true;
        }
      });
    }
  }

  // Full width video block
  function fullWidthVideo() {
    $(".full-video__overlay").on("click", function (e) {
      e.preventDefault();

      var video = $(this).parent().siblings(".full-video__video");

      video.show();
      video.trigger("play");
    });
  }

  // Course facilitators block popups
  function courseFacilitatorsPopups() {
    $(".course-facilitators__item-more").on("click", function (e) {
      e.preventDefault();

      var facilitatorID = $(this).data("course-facilitator"),
        popup = $(
          '.course-facilitators__popup[data-course-facilitator="' +
            facilitatorID +
            '"]'
        );

      popup.addClass("course-facilitators__popup--show");
    });

    $(
      ".course-facilitators__popup-overlay, .course-facilitators__popup-close"
    ).on("click", function () {
      var popup = $(this).closest(".course-facilitators__popup");

      popup.removeClass("course-facilitators__popup--show");
    });
  }

  // Testimonials block popups
  function testimonialsPopups() {
    $(".testimonials-block__item-more").on("click", function (e) {
      e.preventDefault();

      var facilitatorID = $(this).data("testimonial"),
        popup = $(
          '.testimonials-block__popup[data-testimonial="' + facilitatorID + '"]'
        );

      popup.addClass("testimonials-block__popup--show");
    });

    $(
      ".testimonials-block__popup-overlay, .testimonials-block__popup-close"
    ).on("click", function () {
      var popup = $(this).closest(".testimonials-block__popup");

      popup.removeClass("testimonials-block__popup--show");
    });
  }

  // Accordions block
  function accordionsBlock() {
    $(".accordions-block__heading").on("click", function (e) {
      e.preventDefault();

      var accordion = $(this).closest(".accordions-block__accordion"),
        content = accordion.find(".accordions-block__content");

      content.slideToggle(300);
      accordion.toggleClass("accordions-block__accordion--open");

      $(".accordions-block__content").not(content).slideUp(300);
      $(".accordions-block__accordion")
        .not(accordion)
        .removeClass("accordions-block__accordion--open");
    });
  }

  // Header menu toggle
  function menuToggle() {
    $("#menu-toggle").on("click", function () {
      $(this).toggleClass("site-header__menu-toggle--open");
      $("#menu-primary-menu").slideToggle();
    });

    $(".submenu-toggle").on("click", function () {
      var submenu = $(this).next(".sub-menu");
      $(this).toggleClass("submenu-toggle--open");
      submenu.slideToggle();
    });
  }

  // Header search toggle
  function searchToggle() {
    var header = $("#masthead");

    $("#search-toggle").on("click", function () {
      header.toggleClass("site-header--search-open");
      $("#header-search").focus();
    });

    $("body").on("click", function (event) {
      if (!$(event.target).is("#search-toggle, #header-search")) {
        header.removeClass("site-header--search-open");
      }
    });
  }

  // Accordion shortcode
  function accordionShortcode() {
    $(".accordion-shortcode__title").on("click", function () {
      var accordion = $(this).parent(".accordion-shortcode"),
        content = $(this).prev(".accordion-shortcode__content");

      content.slideToggle();
      accordion.toggleClass("accordion-shortcode--open");
      $(this).slideUp();
    });
  }

  // Featured testimonials slider
  function featuredTestimonials() {
    var slider = $(".featured-testimonials__slider");

    slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      speed: 300,
      adaptiveHeight: true,
    });
  }

  // Featured courses slider
  function featuredCourses() {
    var slider = $(".featured-courses__slider");

    slider.slick({
      arrows: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  }

  // Video library block
  function videoLibrary() {
    $(document).on("lity:close", function (event, instance) {
      var videos = $(".video-library__video");

      videos.trigger("pause");
    });
  }

  // Product detail tabs
  function productTabs() {
    $(".product-details__tab").on("click", function (e) {
      e.preventDefault();

      var tabID = $(this).data("section"),
        section = $('.product-details__section[data-section="' + tabID + '"]'),
        tabs = $(".product-details__tab"),
        sections = $(".product-details__section");

      sections.removeClass("product-details__section--open");
      tabs.removeClass("product-details__tab--active");

      $(this).addClass("product-details__tab--active");
      section.addClass("product-details__section--open");
    });
  }
})(jQuery);
